<template>
  <div :class="$vuetify.breakpoint.mdAndUp ? 'px-12' : ' py-4 background-white'">
    <v-snackbar class="pa-0" style="z-index:1000 !important;top:7%" light timeout="-1" v-model="alertShow" width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'check_circle' : 'cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
    <v-card elevation="0" class="justify-space-around referralLayout hidden-sm-and-down">
      <v-img :src="`/static/image/referral/${language}_referral_banner3.jpg`" :aspect-ratio="1816 / 420">
        <div class="fill-height">
          <v-row no-gutters align="end" class="fill-height">
            <v-col cols="6" class="referral-header" :class="[`${language}`]" style="">
              <label class="ml-3 referral-title">{{ $t(`label.referralProgram`) }}</label>
              <p class="ml-3 white--text subtitle-2" v-html="$t(`label.referralSubtest1`)"></p>
              <p class="ml-3 white--text subtitle-2" v-html="$t(`label.referralSubtest2`)"></p>
              <p class="ml-3 white--text subtitle-2" v-html="$t(`label.referralSubtest3`)"></p>
            </v-col>
            <v-col cols="12" class="text-right">
              <!-- <v-btn elevation="0" class="referral-tab" to="tier" active-class="secondary" depressed @click="selectedTab = 'inbox'">{{$t(`button.referralTier`)}}</v-btn> -->
              <v-btn elevation="0" class="referral-tab" :to="getToRouteCommission" active-class="secondary" depressed @click="selectedTab = 'announcement'">{{ $t(`button.commission`) }}</v-btn>
              <v-btn elevation="0" class="referral-tab" to="report" active-class="secondary" depressed @click="selectedTab = 'inbox'">{{ $t(`button.redeemReport`) }}</v-btn>
              <v-btn elevation="0" class="referral-tab" to="history" active-class="secondary" depressed @click="selectedTab = 'announcement'">{{ $t(`button.redeemHistory`) }}</v-btn>
            </v-col>
          </v-row>
        </div>
      </v-img>
      <v-card-text v-if="isLoggedIn || currentRouteName == 'tier'" class="referralContainer">
        <router-view></router-view>
      </v-card-text>
      <v-card-text v-else class="referralContainer">
        <v-row v-if="!isLoggedIn">
          <v-col cols="12" class="text-center">
            <label color="title_color2">{{ $t(`message.referralNoLogin`) }}</label>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn height="auto" @click="openLoginDialog" color="primary" class="mr-6 primary-button embedded-login-button pa-2 font-weight-bold">{{ $t(`button.login`) }}</v-btn>
            <v-btn height="auto" @click="openRegisterDialog" color="primary" class="mr-6 primary-button embedded-login-button pa-2 font-weight-bold">{{ $t(`label.signUp`) }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card elevation="0" class="justify-space-around referralLayout hidden-md-and-up">
      <v-img :src="`/static/image/referral/${language}_referral_banner3.jpg`" :aspect-ratio="1816 / 420">
        <!-- <div class="fill-height"> -->
        <v-row class="fill-height" no-gutters justify="end">
          <v-col cols="12">
            <v-expansion-panels accordion flat class="mobile-referral-expansion">
              <v-expansion-panel>
                <v-expansion-panel-header class="">
                  <label class="referral-title">{{ $t(`label.referralProgram`) }}</label>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="white--text subtitle-2 elevation-0 referral-header">
                  <p class="ml-3 white--text subtitle-2" v-html="$t(`label.referralSubtest1`)"></p>
                  <p class="ml-3 white--text subtitle-2" v-html="$t(`label.referralSubtest2`)"></p>
                  <p class="ml-3 white--text subtitle-2" v-html="$t(`label.referralSubtest3`)"></p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-col cols="12" class="text-end d-flex justify-center align-end">
            <!-- <v-btn elevation="0" class="referral-tab-mobile" to="tier" active-class="mobile-nav-active" depressed>
              <referral-tier class="mobile-icon"></referral-tier>
              <span class="ml-1" v-if="$route.name == 'tier'">{{$t(`button.referralTier`)}}</span>
            </v-btn> -->
            <v-btn elevation="0" class="referral-tab-mobile" to="summary" active-class="mobile-nav-active" depressed>
              <!--              <referral-summary class="mobile-icon"></referral-summary>-->
              <span class="btnGroup-text">{{ $t(`button.commission`) }}</span>
              <!--              <span class="ml-1" v-if="$route.name == 'summary'">{{ $t(`button.summary`) }}</span>-->
            </v-btn>
            <v-btn elevation="0" class="referral-tab-mobile" :to="getToRoute" active-class="mobile-nav-active" depressed>
              <!--              <referral-report class="mobile-icon"></referral-report>  v-if="$route.name === 'status' || $route.name === 'downlinereport'"-->
              <span class="btnGroup-text">{{ $t(`button.bonus`) }}</span>
            </v-btn>
            <v-btn elevation="0" class="referral-tab-mobile" to="report" active-class="mobile-nav-active" depressed>
              <!--              <referral-report class="mobile-icon"></referral-report>-->
              <span class="btnGroup-text">{{ $t(`button.redeemReport`) }}</span>
              <!--              <span class="ml-1" v-if="$route.name == 'report'">{{ $t(`button.referralReport`) }}</span>-->
            </v-btn>

            <v-btn elevation="0" class="referral-tab-mobile" to="history" active-class="mobile-nav-active" depressed>
              <!--              <above use  style="width: calc(100% / 4);" <-- referral-history class="mobile-icon"></referral-history>-->
              <span class="btnGroup-text">{{ $t(`button.redeemHistory`) }}</span>
              <!--              <span class="ml-1" v-if="$route.name == 'history'">{{ $t(`button.redeemHistory`) }}</span>-->
            </v-btn>
          </v-col>
        </v-row>
        <!-- </div> -->
      </v-img>
      <v-card-text v-if="isLoggedIn || currentRouteName == 'tier'" class="referralContainer">
        <router-view></router-view>
      </v-card-text>
      <v-card-text v-else class="referralContainer">
        <v-row v-if="!isLoggedIn">
          <v-col cols="12" class="text-center">
            <label color="title_color2">{{ $t(`message.referralNoLogin`) }}</label>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn height="auto" @click="openLoginDialog" color="primary" class="mr-6 primary-button embedded-login-button pa-2 font-weight-bold text_reverse--text">
              {{ $t(`button.login`) }}
            </v-btn>
            <v-btn height="auto" @click="openRegisterDialog" color="primary" class="mr-6 primary-button embedded-login-button pa-2 font-weight-bold text_reverse--text">
              {{ $t(`label.signUp`) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ReferralSummary from '@/assets/svg/referral-summary.svg'
import ReferralHistory from '@/assets/svg/referral-history.svg'
import ReferralReport from '@/assets/svg/referral-report.svg'
import ReferralTier from '@/assets/svg/referral-tier.svg'
import { uiHelper, locale } from '@/util'
import { ROUTE_NAME } from '@/constants/route.constants'
// import ReferralTier from './referralTier.vue'
// import ReferralSummary from './referralSummary.vue'
// import ReferralReport from './referralReport.vue'
// import ReferralHistory from './referralHistory.vue'
export default {
  name: 'referralLayout',
  metaInfo: {
    title: `${locale.getMessage('meta.default_title')}`,
    titleTemplate: `%s`,
    meta: [
      { charset: 'utf-8' },
      {
        name: 'description',
        content: `${locale.getMessage('meta.default_description')}`
      },
      { name: 'keyword', content: `${locale.getMessage('meta.default_keyword')}` }
    ],
    link: [{ rel: 'canonical', href: `https://www.krikya.group/referral/` }]
  },
  components: {
    ReferralSummary,
    ReferralHistory,
    ReferralReport,
    ReferralTier
  },
  data: () => ({
    language: uiHelper.getLanguage(),
    selectedTab: 'tier',
    alertShow: false,
    alertSuccess: false,
    alertMessage: ''
  }),
  computed: {
    getToRouteCommission() {
      return this.$route.name === ROUTE_NAME.REFERRAL_DOWNLINE_REPORT ? ROUTE_NAME.REFERRAL_DOWNLINE_REPORT : ROUTE_NAME.REFERRAL_SUMMARY
    },
    getToRoute() {
      return this.$route.name === ROUTE_NAME.REFERRAL_DOWNLINE_REPORT ? ROUTE_NAME.REFERRAL_DOWNLINE_REPORT : ROUTE_NAME.REFERRAL_STATUS
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    currentRouteName() {
      return this.$route.name
    }
  },
  watch: {
    alertShow() {
      if (!this.alertShow) {
        this.alertSuccess = false
        this.alertMessage = ''
      }
    }
  },
  methods: {
    // getToRouteCommission() {
    //   return this.$route.name === 'downlinereport' ? 'downlinereport' : 'summary';
    // },
    // getToRoute() {
    //     // Set the 'to' attribute based on your logic
    //   return this.$route.name === 'downlinereport' ? 'downlinereport' : 'status';
    // },
    // isActive() {
    //   // Determine the active state based on your logic
    //   return this.$route.name === 'status' || this.$route.name === 'downlinereport';
    // },
    openLoginDialog() {
      this.$parent.openLoginDialog()
    },
    openRegisterDialog() {
      this.$parent.openRegisterDialog()
    },
    openAlert(success, msg) {
      this.alertSuccess = success
      this.alertMessage = msg
      this.alertShow = true
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'NotoSansBengali-Medium';
  font-display: swap;
  src: url('/static/font/NotoSansBengali-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'NotoSansBengali-Bold';
  font-display: swap;
  src: url('/static/font/NotoSansBengali-Bold.ttf') format('truetype');
}

.background-white {
  background: white;
}
.profile-alert-success {
  .v-icon {
    color: #25b34b;
  }
}

.profile-alert-failed {
  .v-icon {
    color: #dc2d2d;
  }
}

.referralLayout {
  border-radius: 20px !important;

  .orange-highlight {
    font-family: 'ReadexPro-Bold', sans-serif;
    color: var(--v-background_2-base);
  }

  .referral-title {
    background: -webkit-linear-gradient(45deg, #ff7800, Yellow, #ffba00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'ReadexPro-Bold', sans-serif;
    font-size: 2.5rem;
  }

  .referral-header {
    margin-left: 10%;

    &.bd {
      font-family: 'NotoSansBengali-Medium', sans-serif !important;

      .referral-title {
        font-family: 'NotoSansBengali-Bold', sans-serif;
      }

      .orange-highlight {
        font-family: 'NotoSansBengali-Bold', sans-serif !important;
      }
    }
  }

  &.hidden-md-and-up {
    .referral-title {
      font-size: 1.5rem;
    }
  }

  .referralContainer {
    border: 1px solid #ebebeb;
    padding: 36px 16px;
  }

  .referral-tab {
    width: 150px;
    margin-right: 16px;
    padding: 10px;
    background-color: #fff;
    text-transform: capitalize;
    letter-spacing: 0px !important;
  }

  .referral-tab-mobile {
    margin-right: 10px;
    background-color: #fff;
    text-transform: capitalize;
    padding: 0px 10px !important;
    letter-spacing: 0px !important;
  }

  .mobile-nav-active {
    background-color: var(--v-secondary-base) !important;
    color: #fff !important;
    font-size: 14px;

    span {
      word-break: break-all;
      white-space: pre-line;
    }

    .mobile-icon {
      fill: #fff !important;

      path {
        fill: #fff !important;
      }
    }
  }

  .embedded-login-button {
    width: 120px;
  }
}

.mobile-referral-expansion {
  .v-expansion-panel {
    background-color: transparent !important;

    .v-expansion-panel-header {
      .v-expansion-panel-header__icon {
        i {
          color: #fff !important;
        }
      }
    }
  }
}

@media (max-width: 959px) {
  .embedded-mobile-login-button {
    width: 70px !important;
    height: 30px !important;
    font-size: 12px !important;
  }
  .embedded-mobile-register-button {
    width: 90px !important;
  }

  .referral-header {
    margin: 0 !important;
  }
}

@media (max-width: 425px) {
  .referralLayout {
    border-radius: 20px !important;

    .referralContainer {
      border: 1px solid #ebebeb;
      padding: 16px 4px;
    }

    .referral-tab-mobile {
      margin-right: 5px;
      background-color: #fff;
      text-transform: capitalize;
      padding: 0px 5px !important;
      letter-spacing: 0px !important;
    }

    .mobile-nav-active {
      background-color: var(--v-secondary-base) !important;
      color: #fff !important;
      font-size: 12px;
    }
  }
}
@media screen and (max-width: 480px) {
  .btnGroup-text {
    font-size: 11px;
  }
}
@media screen and (max-width: 390px) {
  .btnGroup-text {
    font-size: 9.5px;
  }
}
</style>
