<template>
  <div>
    <v-row>
      <v-col cols="12" v-if="!$vuetify.breakpoint.xsOnly">
        <v-row no-gutters>
          <v-col cols="9">
            <v-row no-gutters>
              <v-col cols="4" v-if="!$vuetify.breakpoint.xsOnly">
                <v-card elevation="0" style="border-radius: 10px 0px 0px 10px !important;" class="commission-content-holder full-height primary white--text text-center pa-2 mb-3">
                  <v-card-text style="padding: 5px;">
                    <v-row no-gutters align="end">
                      <v-col cols="12" class="mb-3 white--text d-flex justify-start">
                        <label>{{ $t(`label.todayCommission`) }}</label>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-card style="background: #003D24;" color="white--text text-left py-2 pl-6 mb-3">
                          {{ overview.commission_balance | currency(currentCurrency) }}
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row no-gutters align="end">
                      <v-col cols="12" class="mb-3 white--text d-flex justify-start">
                        <label>{{ $t(`label.monthCommission`) }}</label>
                      </v-col>
                    </v-row>
                    <v-row no-gutters align="start">
                      <v-col cols="12">
                        <v-card style="background: #003D24;" color=" white--text text-left py-2 pl-6">
                          {{ overview.commission_month | currency(currentCurrency) }}
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-divider class="custom-divider"></v-divider>
                    <v-row justify="center" no-gutters>
                      <v-col cols="12" class="mb-3 d-flex justify-start">
                        <label class="white--text">{{ $t(`label.commissionToRedeem`) }}</label>
                      </v-col>
                      <v-col cols="12" class="mb-0">
                        <v-form ref="redeemForm">
                          <app-form-field
                            :customClass="'commission-redeem-field input-field elevation-0 hide-details'"
                            :enabledLabel="false"
                            v-model.trim="redeemCommissionAmt"
                            :readonly="true"
                            :prefix="currencySymbolMapp(currentCurrency)"
                            :rules="validator.requiredRules()"
                          ></app-form-field>
                        </v-form>
                      </v-col>
                      <v-col cols="12">
                        <app-button customClass="dialog-button text-capitalize" :action="this.redeemCommissionConfirmationDialog" :title="$t(`button.redeem`)"></app-button>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="8" class="tier-container-box">
                <v-row style="">
                  <v-col cols="12">
                    <div class="tier-container font-weight-bold">{{ $t(`label.referralTier`) }}</div>
                  </v-col>
                </v-row>
                <v-row no-gutters align="end" style="padding-top: 16px;">
                  <v-col cols="3">
                    <v-card elevation="0" color="border-left-radius primary white--text text-center pa-2 mb-3 mr-1">
                      {{ $t(`label.total`) }}
                    </v-card>
                  </v-col>
                  <v-col cols="3">
                    <v-card elevation="0" color="border-right-radius primary white--text text-center pa-2 mb-3 mr-1">
                      {{ totalPoint }}
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="d-flex align-center">
                  <v-col cols="3" class="d-flex align-center justify-center" style="padding: 0px;">
                    <v-col cols="11" class="d-flex justify-center" style="padding: 0px;">
                      <div class="tier-head pa-1 tier-container">
                        <img src="/static/image/referral/referral-people.svg" class="avatar-size" width="auto" height="60" alt="krikya-referral" />
                      </div>
                    </v-col>
                    <v-col cols="1" style="padding: 0px;">
                      <img style="transform: rotate(270deg);" class="tier-container" src="/static/image/referral/referral_arrow_down.svg" width="auto" height="16" alt="krikya-referral" />
                    </v-col>
                  </v-col>
                  <v-col cols="3" class="d-flex align-center justify-center" style="padding: 0px;">
                    <v-col cols="11" class="d-flex justify-center padding-left" style="padding: 0px;">
                      <div class="tier-head pa-1 tier-container">
                        <img src="/static/image/referral/referral-people.svg" class="avatar-size" width="auto" height="60" alt="krikya-referral" />
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <img class="tier-info" v-bind="attrs" v-on="on" src="/static/image/referral/referral-info.svg" width="auto" height="18" alt="krikya-referral" />
                          </template>
                          <v-row no-gutters class="tier-tool-tip">
                            <v-col cols="12">
                              {{ stringFormat($t(`label.tier`), 1) }}
                            </v-col>
                            <v-col cols="12">
                              {{ stringFormat($t(`label.commissionTips`), getConfig(1)) }}
                            </v-col>
                          </v-row>
                        </v-tooltip>
                      </div>
                    </v-col>
                    <v-col cols="1" style="padding: 0px;">
                      <img style="transform: rotate(270deg);" class="tier-container" src="/static/image/referral/referral_arrow_down.svg" width="auto" height="16" alt="krikya-referral" />
                    </v-col>
                  </v-col>
                  <v-col cols="3" class="d-flex align-center justify-center" style="padding: 0px;">
                    <v-col cols="11" class="d-flex justify-center padding-left" style="padding: 0px;">
                      <div class="tier-head pa-1 tier-container">
                        <img src="/static/image/referral/referral-people.svg" class="avatar-size" width="auto" height="60" alt="krikya-referral" />
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <img class="tier-info" v-bind="attrs" v-on="on" src="/static/image/referral/referral-info.svg" width="auto" height="18" alt="krikya-referral" />
                          </template>
                          <v-row no-gutters class="tier-tool-tip">
                            <v-col cols="12">
                              {{ stringFormat($t(`label.tier`), 2) }}
                            </v-col>
                            <v-col cols="12">
                              {{ stringFormat($t(`label.commissionTips`), getConfig(2)) }}
                            </v-col>
                          </v-row>
                        </v-tooltip>
                      </div>
                    </v-col>
                    <v-col cols="1" style="padding: 0px;">
                      <img style="transform: rotate(270deg);" class="tier-container" src="/static/image/referral/referral_arrow_down.svg" width="auto" height="16" alt="krikya-referral" />
                    </v-col>
                  </v-col>
                  <v-col cols="3" class="d-flex align-center justify-center" style="padding: 0px;">
                    <v-col cols="11" class="d-flex justify-center" style="padding: 0px;">
                      <div class="tier-head pa-1 tier-container">
                        <img src="/static/image/referral/referral-people.svg" class="avatar-size" width="auto" height="60" alt="krikya-referral" />
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <img class="tier-info" v-bind="attrs" v-on="on" src="/static/image/referral/referral-info.svg" width="auto" height="18" alt="krikya-referral" />
                          </template>
                          <v-row no-gutters class="tier-tool-tip">
                            <v-col cols="12">
                              {{ stringFormat($t(`label.tier`), 3) }}
                            </v-col>
                            <v-col cols="12">
                              {{ stringFormat($t(`label.commissionTips`), getConfig(3)) }}
                            </v-col>
                          </v-row>
                        </v-tooltip>
                      </div>
                    </v-col>
                    <!--                  <v-col cols="1">-->
                    <!--&lt;!&ndash;                    <img style="transform: rotate(270deg);" class="tier-container" src="/static/image/referral/referral_arrow_down.svg" width="auto"&ndash;&gt;-->
                    <!--&lt;!&ndash;                         height="16" alt="krikya-referral"/>&ndash;&gt;-->
                    <!--                  </v-col>-->
                  </v-col>
                </v-row>
                <v-row style="margin-top: -12px;">
                  <v-col cols="3" style="padding: 10px 3px;">
                    <v-card class="primary text-center tier-container">
                      <v-card-text class="pa-1">
                        <v-row no-gutters>
                          <v-col class="no-gutters" cols="12">
                            <label class="white--text detail">{{ memberInfo.name || memberInfo.memberCode }}</label>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="3" style="padding: 10px 3px;">
                    <v-card class="primary text-center tier-container">
                      <v-card-text class="pa-1">
                        <v-row no-gutters>
                          <v-col class="no-gutters" cols="12">
                            <label class="white--text detail">{{ overview.downline_lv1 }}</label>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="3" style="padding: 10px 3px;">
                    <v-card class="primary text-center tier-container">
                      <v-card-text class="pa-1">
                        <v-row no-gutters>
                          <v-col class="no-gutters" cols="12">
                            <label class="white--text detail">{{ overview.downline_lv2 }}</label>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="3" style="padding: 10px 3px;">
                    <v-card class="primary text-center tier-container">
                      <v-card-text class="pa-1">
                        <v-row no-gutters>
                          <v-col class="no-gutters" cols="12">
                            <label class="white--text detail">{{ overview.downline_lv3 }}</label>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <!--              <v-row class="referral-tier">-->
                <!--                <v-col cols=3>-->
                <!--                  <div class="tier-head pa-1 tier-container">-->
                <!--                    <img src="/static/image/referral/referral-people.svg" class="avatar-size" width="auto" height="60" alt="krikya-referral"/>-->
                <!--                    <img style="transform: rotate(270deg);right: 0px; top:30px; position: absolute;" class="tier-container" src="/static/image/referral/referral_arrow_down.svg" width="auto"-->
                <!--                         height="16" alt="krikya-referral"/>-->
                <!--                  </div>-->
                <!--                  <v-card class="primary text-center tier-container">-->
                <!--                    <v-card-text class="pa-1">-->
                <!--                      <v-row no-gutters>-->
                <!--                        <v-col cols=12 no-gutters>-->
                <!--                          <label class="detail">{{ memberInfo.name || memberInfo.memberCode }}</label>-->
                <!--                        </v-col>-->
                <!--                      </v-row>-->
                <!--                    </v-card-text>-->
                <!--                  </v-card>-->
                <!--                </v-col>-->
                <!--                &lt;!&ndash;              <v-col cols=1 class="d-flex justify-center">&ndash;&gt;-->
                <!--                &lt;!&ndash;                <img style="transform: rotate(270deg);" class="tier-container" src="/static/image/referral/referral_arrow_down.svg" width="auto"&ndash;&gt;-->
                <!--                &lt;!&ndash;                     height="16" alt="krikya-referral"/>&ndash;&gt;-->
                <!--                &lt;!&ndash;              </v-col>&ndash;&gt;-->
                <!--                <v-col cols=3>-->
                <!--                  <div class="tier-head pa-1 tier-container">-->
                <!--                    <img src="/static/image/referral/referral-people.svg" class="avatar-size" width="auto" height="60" alt="krikya-referral"/>-->
                <!--                    <v-tooltip right>-->
                <!--                      <template v-slot:activator="{ on, attrs }">-->
                <!--                        <img class="tier-info" v-bind="attrs" v-on="on" src="/static/image/referral/referral-info.svg"-->
                <!--                             width="auto" height="18" alt="krikya-referral"/>-->
                <!--                        <img style="transform: rotate(270deg);right: 0px; top:30px; position: absolute;" class="tier-container" src="/static/image/referral/referral_arrow_down.svg" width="auto"-->
                <!--                             height="16" alt="krikya-referral"/>-->
                <!--                      </template>-->
                <!--                      <v-row no-gutters class="tier-tool-tip">-->
                <!--                        <v-col cols=12>-->
                <!--                          {{ stringFormat($t(`label.tier`), 1) }}-->
                <!--                        </v-col>-->
                <!--                        <v-col cols=12>-->
                <!--                          {{ stringFormat($t(`label.commissionTips`), getConfig(1)) }}-->
                <!--                        </v-col>-->
                <!--                      </v-row>-->
                <!--                    </v-tooltip>-->
                <!--                  </div>-->
                <!--                  <v-card class="primary text-center tier-container">-->
                <!--                    <v-card-text class="pa-1">-->
                <!--                      <v-row no-gutters>-->
                <!--                        <v-col cols=12 no-gutters>-->
                <!--                          <label class="detail">{{ overview.downline_lv1 }}</label>-->
                <!--                        </v-col>-->
                <!--                      </v-row>-->
                <!--                    </v-card-text>-->
                <!--                  </v-card>-->
                <!--                </v-col>-->
                <!--                &lt;!&ndash;              <v-col cols=1 class="d-flex justify-center">&ndash;&gt;-->
                <!--                &lt;!&ndash;                <img style="transform: rotate(270deg);right: 0px; top:30px; position: absolute;" class="tier-container" src="/static/image/referral/referral_arrow_down.svg" width="auto"&ndash;&gt;-->
                <!--                &lt;!&ndash;                     height="16" alt="krikya-referral"/>&ndash;&gt;-->
                <!--                &lt;!&ndash;              </v-col>&ndash;&gt;-->
                <!--                <v-col cols=3>-->
                <!--                  <div class="tier-head pa-1 tier-container">-->
                <!--                    <img src="/static/image/referral/referral-people.svg" class="avatar-size" width="auto" height="60" alt="krikya-referral"/>-->
                <!--                    <v-tooltip right>-->
                <!--                      <template v-slot:activator="{ on, attrs }">-->
                <!--                        <img class="tier-info" v-bind="attrs" v-on="on" src="/static/image/referral/referral-info.svg"-->
                <!--                             width="auto" height="18" alt="krikya-referral"/>-->
                <!--                        <img style="transform: rotate(270deg);right: 0px; top:30px; position: absolute;" class="tier-container" src="/static/image/referral/referral_arrow_down.svg" width="auto"-->
                <!--                             height="16" alt="krikya-referral"/>-->
                <!--                      </template>-->
                <!--                      <v-row no-gutters class="tier-tool-tip">-->
                <!--                        <v-col cols=12>-->
                <!--                          {{ stringFormat($t(`label.tier`), 2) }}-->
                <!--                        </v-col>-->
                <!--                        <v-col cols=12>-->
                <!--                          {{ stringFormat($t(`label.commissionTips`), getConfig(2)) }}-->
                <!--                        </v-col>-->
                <!--                      </v-row>-->
                <!--                    </v-tooltip>-->
                <!--                  </div>-->
                <!--                  <v-card class="primary text-center tier-container">-->
                <!--                    <v-card-text class="pa-1">-->
                <!--                      <v-row no-gutters>-->
                <!--                        <v-col cols=12 no-gutters>-->
                <!--                          <label class="detail">{{ overview.downline_lv2 }}</label>-->
                <!--                        </v-col>-->
                <!--                      </v-row>-->
                <!--                    </v-card-text>-->
                <!--                  </v-card>-->
                <!--                </v-col>-->
                <!--                &lt;!&ndash;              <v-col cols=1 class="d-flex justify-center">&ndash;&gt;-->
                <!--                &lt;!&ndash;                <img style="transform: rotate(270deg);" class="tier-container" src="/static/image/referral/referral_arrow_down.svg" width="auto"&ndash;&gt;-->
                <!--                &lt;!&ndash;                     height="16" alt="krikya-referral"/>&ndash;&gt;-->
                <!--                &lt;!&ndash;              </v-col>&ndash;&gt;-->
                <!--                <v-col cols=3>-->
                <!--                  <div class="tier-head pa-1 tier-container">-->
                <!--                    <img src="/static/image/referral/referral-people.svg" class="avatar-size" width="auto" height="60" alt="krikya-referral"/>-->
                <!--                    <v-tooltip right>-->
                <!--                      <template v-slot:activator="{ on, attrs }">-->
                <!--                        <img class="tier-info" v-bind="attrs" v-on="on" src="/static/image/referral/referral-info.svg"-->
                <!--                             width="auto" height="18" alt="krikya-referral"/>-->
                <!--                      </template>-->
                <!--                      <v-row no-gutters class="tier-tool-tip">-->
                <!--                        <v-col cols=12>-->
                <!--                          {{ stringFormat($t(`label.tier`), 3) }}-->
                <!--                        </v-col>-->
                <!--                        <v-col cols=12>-->
                <!--                          {{ stringFormat($t(`label.commissionTips`), getConfig(3)) }}-->
                <!--                        </v-col>-->
                <!--                      </v-row>-->
                <!--                    </v-tooltip>-->
                <!--                  </div>-->
                <!--                  <v-card class="primary text-center tier-container">-->
                <!--                    <v-card-text class="pa-1">-->
                <!--                      <v-row no-gutters>-->
                <!--                        <v-col cols=12 no-gutters>-->
                <!--                          <label class="detail">{{ overview.downline_lv3 }}</label>-->
                <!--                        </v-col>-->
                <!--                      </v-row>-->
                <!--                    </v-card-text>-->
                <!--                  </v-card>-->
                <!--                </v-col>-->
                <!--              </v-row>-->
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col v-if="!$vuetify.breakpoint.xsOnly" cols="12" class="border-referralbonus">
                <v-col cols="12" style="display: inline-flex; padding: 12px 24px 0px !important;">
                  <!--          <label class="mx-auto ml-0 mr-1 pa-1" style="width: fit-content">{{ $t(`label.expiredDate`) }} :</label>-->
                  <label class="mx-auto ml-0 pa-1" style="width: fit-content; font-weight: bold">
                    <!--            {{ downlineDetail.expired_at == null || downlineDetail.expired_at == '' ? '-' : downlineDetail.expired_at }}-->
                    {{ $t(`label.referralBonus`) }}
                  </label>
                  <label class="ml-0 pa-1" style="width: fit-content; font-weight: bold">
                    {{ $t(`label.expiredDate`) }} : {{ downlineDetail.is_expired == true ? $t(`label.expired`) : $t(`label.noExpired`) }}
                  </label>
                </v-col>
                <v-col cols="12" style="display: flex;" v-if="!$vuetify.breakpoint.xsOnly">
                  <v-col cols="6">
                    <v-card elevation="0" class="commission-content-card" color="commission-content-holder primary pa-2 mb-3 col-5">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <label class="mx-auto ml-0 mr-6 pa-1 white--text" style="width: 100px">{{ $t(`label.freeBonus`) }} ৳</label>
                            <v-card class="font-weight-bold justify-start d-flex" color="title_color2 text-center pa-1 ml-0 mx-auto input-card">
                              {{ decimalTruncate(downlineDetail.bonus_amount, 2) }}
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <label class="mx-auto ml-0 mr-6 pa-1 white--text" style="width: 100px">{{ $t(`label.deposit`) }}</label>
                            <v-card elevation="0" class="font-weight-bold justify-start d-flex" color="title_color2 text-center pa-1 mx-auto ml-0 input-card">
                              {{ decimalTruncate(downlineDetail.current_deposit, 2) }} /
                              {{ decimalTruncate(downlineDetail.required_deposit, 2) }}
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <label class="mx-auto ml-0 mr-6 pa-1 white--text" style="width: 100px">{{ $t(`label.turnover`) }}</label>
                            <v-card elevation="0" class="font-weight-bold justify-start d-flex" color="title_color2 text-center pa-1 mx-auto ml-0 input-card">
                              {{ decimalTruncate(downlineDetail.current_valid_bet, 2) }} /
                              {{ decimalTruncate(downlineDetail.required_valid_bet, 2) }}
                            </v-card>
                          </v-col>
                        </v-row>
                        <!--<app-button class="dialog-button mt-6" style="width: 315px" :disabled="downlineDetail.is_claimed != 1" :action="this.claimBonusDialog" :title="$t(`button.claimBonus`)"></app-button>-->
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card elevation="0" class="commission-content-card" color="commission-content-holder base pa-2 mb-3 col-7">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" class="text-center">
                            <label class="--v-primary-base" style="font-size: 16px">{{ $t(`label.youReferralStatus`) }}</label>
                            <div style="display: flex" class="mt-10 justify-center">
                              <div style="width: fit-content; border-right: 2px dotted rgba(8,8,8);" class="mt-6 pa-3">
                                <div class="text-center">
                                  <label class="referral-text">{{ uplineReferral.accepted_referral }}</label>
                                </div>
                                <div>
                                  <label>{{ $t(`label.friendAccepted`) }}</label>
                                </div>
                              </div>
                              <div style="width: fit-content" class="mt-6 pa-3">
                                <div class="text-center">
                                  <label class="referral-text">{{ uplineReferral.current_referral }}</label>
                                </div>
                                <div>
                                  <label>{{ $t(`label.friendCompleted`) }}</label>
                                </div>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-col>
                <v-col cols="12" style="justify-content: end; display: flex;">
                  <v-row style="justify-content: end; padding: 12px 24px 12px !important;">
                    <v-btn style="width: 200px;" class="dialog-button subtitle-1 full-width text-capitalize pa-2" @click="redirectDownLineReport()">
                      {{ $t(`button.downLine`) }}
                    </v-btn>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="!$vuetify.breakpoint.xsOnly" style="padding-left: 15px;" cols="3">
            <v-card elevation="0" class="commission-content-holder base pa-2 mb-3 full-height">
              <v-card-text>
                <v-row no-gutters style="margin-top: 20px;">
                  <v-col cols="10" class="d-flex flex-column justify-center" style="margin:auto">
                    <v-row no-gutters>
                      <v-col class="font-weight-bold text-center">{{ $t(`label.id`) }}:{{ overview.member_id }}</v-col>
                      <!--                      <v-col class="font-weight-bold text-right">{{ overview.member_id }}</v-col>-->
                    </v-row>
                    <v-row no-gutters>
                      <v-col class="font-weight-bold text-center">{{ $t(`field.referralCode`) }}:{{ overview.my_referral_code }}</v-col>
                      <!--                      <v-col class="font-weight-bold text-right"></v-col>-->
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex justify-center">
                    <vue-qr-code v-if="overview.my_referral_code" class="qrCode" :text="share_url" :size="105"></vue-qr-code>
                  </v-col>
                  <v-col cols="12" class="mb-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="11">
                        <!-- <v-img class="mr-2" src="/static/svg/fb_btn.svg" @click="share()"> </v-img> -->
                        <v-btn class="text--style dialog-button subtitle-1 full-width text-capitalize pa-2" @click="share()">
                          <v-icon class="text--style mr-2">mdi-share-variant</v-icon>
                          <span class="text--style">{{ webShareApiSupported ? $t(`button.shareNow`) : $t(`button.copy_ref`) }}</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="5" v-if="$vuetify.breakpoint.xsOnly">
        <v-row>
          <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12" md="7" sm="7">
            <v-col cols="12" style="justify-content: end; display: flex;">
              <v-row style="justify-content: center; margin-bottom: 10px; margin-top: -15px; padding: 0px 12px !important; background: #F1F1F1;">
                <v-col cols="6" class="justify-end d-flex">
                  <v-btn style="width: 80%;background: #33AF83;" class="button-texts white--text dialog-button full-width text-capitalize pa-2" to="status">
                    {{ $t(`button.status`) }}
                  </v-btn>
                </v-col>
                <v-col cols="6" class="justify-start d-flex">
                  <v-btn style="width: 80%;background: #FFFFFF; border: 1px solid #33AF83;" class="button-texts dialog-button full-width text-capitalize pa-2" @click="redirectDownLineReport()">
                    {{ $t(`button.downLine`) }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-card elevation="0" class="commission-content-holder base pa-2">
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="10" :md="7" class="d-flex flex-column justify-center" style="margin:auto">
                    <v-row no-gutters>
                      <v-col class="d-flex justify-center font-weight-bold text-right">{{ $t(`label.id`) }}: {{ overview.member_id }}</v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col class="d-flex justify-center font-weight-bold text-right">{{ $t(`field.referralCode`) }}: {{ overview.my_referral_code }}</v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12" class="d-flex justify-center">
                    <vue-qr-code v-if="overview.my_referral_code" :text="share_url" :size="250"></vue-qr-code>
                  </v-col>
                  <v-col cols="12" md="12" sm="8" class="mb-0">
                    <v-row no-gutters justify="center">
                      <v-col cols="10" lg="6">
                        <!-- <v-img class="mr-2" src="/static/svg/fb_btn.svg" @click="share()"> </v-img> -->
                        <v-btn class="dialog-button subtitle-1 full-width text-capitalize pa-2" @click="share()">
                          <v-icon class="mr-2">mdi-share-variant</v-icon>
                          {{ webShareApiSupported ? $t(`button.shareNow`) : $t(`button.copy_ref`) }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" class="mt-3">
            <!--            <v-card elevation="0" color="commission-content-holder base pa-2 mb-3">-->
            <!--              <v-card-text style="display: inline-flex">-->
            <!--                <v-row justify="center" no-gutters>-->
            <!--                  <v-col cols="9" class="mb-3 text-center">-->
            <!--                    <label>{{ $t(`label.referral`) }}</label>-->
            <!--                  </v-col>-->
            <!--                  <v-col cols="9" class="mb-0">-->
            <!--                    <app-form-field-->
            <!--                      :customClass="'commission-redeem-field-mobile input-field elevation-0 hide-details'"-->
            <!--                      :enabledLabel="false"-->
            <!--                      v-model.trim="referralOverview.my_referral_code"-->
            <!--                      :readonly="true"-->
            <!--                      :rules="validator.requiredRules()"-->
            <!--                    ></app-form-field>-->
            <!--                  </v-col>-->
            <!--                  <v-col cols="9">-->
            <!--                    <app-button class="dialog-button" :action="this.share" :title="$t(`button.shareNow`)"></app-button>-->
            <!--                  </v-col>-->
            <!--                </v-row>-->
            <!--              </v-card-text>-->
            <!--            </v-card>-->
            <div>
              <v-card elevation="0" color="commission-content-holder primary pa-2 mb-3 col-12">
                <v-card-text class="pr-0">
                  <v-row style="display: flex; padding: 0px 12px 0px 0px;">
                    <v-col cols="12" style="display: inline-flex; color: white;    padding: 0px;">
                      <label class="mx-auto ml-0 mr-1 pa-1" style="width: fit-content; font-size: 14px">{{ $t(`label.expiredDate`) }} :</label>
                      <label class="mx-auto ml-0 pa-1" style="width: fit-content; font-weight: bold; font-size: 14px">
                        {{ downlineDetail.expired_at == null || downlineDetail.expired_at == '' ? '-' : downlineDetail.expired_at }}
                      </label>
                      <label class="ml-0 pa-1" style="width: fit-content; font-weight: bold; font-size: 14px">
                        {{ downlineDetail.is_expired == true ? $t(`label.expired`) : $t(`label.noExpired`) }}
                      </label>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12 pl-0 pr-0" style="display: inline-flex; width: 100px">
                      <v-col cols="4">
                        <label class="mx-auto ml-0 mr-6 pa-1 white--text">{{ $t(`label.freeBonus`) }} ৳</label>
                      </v-col>
                      <v-col cols="8">
                        <v-card class="font-weight-bold" color="title_color2 text-center pa-1 ml-0 mx-auto input-card">
                          {{ decimalTruncate(downlineDetail.bonus_amount, 2) }}
                        </v-card>
                      </v-col>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12 pl-0 pr-0" style="display: inline-flex">
                      <v-col cols="4">
                        <label class="mx-auto ml-0 mr-6 pa-1 white--text" style="width: 100px">{{ $t(`label.deposit`) }}</label>
                      </v-col>
                      <v-col cols="8">
                        <v-card color=" title_color2 text-center pa-1 mx-auto ml-0 input-card">
                          {{ decimalTruncate(downlineDetail.required_deposit, 2) }} /
                          {{ decimalTruncate(downlineDetail.current_deposit, 2) }}
                        </v-card>
                      </v-col>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12 pl-0 pr-0" style="display: inline-flex">
                      <v-col cols="4">
                        <label class="mx-auto ml-0 mr-6 pa-1 white--text" style="width: 100px">{{ $t(`label.turnover`) }}</label>
                      </v-col>
                      <v-col cols="8">
                        <v-card elevation="0" color=" title_color2 text-center pa-1 mx-auto ml-0 input-card">
                          {{ decimalTruncate(downlineDetail.required_valid_bet, 2) }} /
                          {{ decimalTruncate(downlineDetail.current_valid_bet, 2) }}
                        </v-card>
                      </v-col>
                    </v-col>
                  </v-row>
                  <!--<app-button class="dialog-button mt-6" style="width: 225px" :disabled="downlineDetail.is_claimed != 1" :action="this.claimBonusDialog" :title="$t(`button.claimBonus`)"></app-button>-->
                </v-card-text>
              </v-card>
              <v-card elevation="0" color="commission-content-holder base pa-2 mb-3 col-12">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <label class="--v-primary-base text-left" style="font-size: 16px">{{ $t(`label.youReferralStatus`) }}</label>
                      <div style="display: flex" class="mt-7 justify-center">
                        <div style="width: fit-content; border-right: 2px dotted rgba(8,8,8);" class="mt-6 pa-3">
                          <div class="text-center">
                            <label class="referral-text">{{ uplineReferral.accepted_referral }}</label>
                          </div>
                          <div>
                            <label>{{ $t(`label.friendAccepted`) }}</label>
                          </div>
                        </div>
                        <div style="width: fit-content" class="mt-6 pa-3">
                          <div class="text-center">
                            <label class="referral-text">{{ uplineReferral.current_referral }}</label>
                          </div>
                          <div>
                            <label>{{ $t(`label.friendCompleted`) }}</label>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-col cols="12">
                <v-divider class="mx-2"></v-divider>
              </v-col>
            </div>
          </v-col>
          <!--          <v-col cols=12 md=5 sm=6>-->

          <!--          </v-col>-->
        </v-row>
      </v-col>

      <!--      <div style="display: flex">-->
      <!--        -->
      <!--      </div>-->
      <v-row></v-row>
      <!--      <v-col cols=12 md=7 sm=7>-->
      <!--        <v-card elevation="0" class="commission-content-holder base pa-2 mb-3 full-height">-->
      <!--          <v-card-text>-->
      <!--            <v-row no-gutters>-->
      <!--              <v-col cols=10 :md="7" class="d-flex flex-column justify-center" style="margin:auto">-->
      <!--                <v-row no-gutters>-->
      <!--                  <v-col>{{ $t(`label.id`) }}:</v-col>-->
      <!--                  <v-col class="font-weight-bold text-right">{{ overview.member_id }}</v-col>-->
      <!--                </v-row>-->
      <!--                <v-row no-gutters>-->
      <!--                  <v-col>{{ $t(`field.referralCode`) }}:</v-col>-->
      <!--                  <v-col class="font-weight-bold text-right">{{ overview.my_referral_code }}</v-col>-->
      <!--                </v-row>-->
      <!--              </v-col>-->
      <!--            </v-row>-->
      <!--            <v-row class="d-flex justify-center">-->
      <!--              <v-col cols="12" class="d-flex justify-center">-->
      <!--                <vue-qr-code v-if="overview.my_referral_code" class="qrCode" :text="share_url"-->
      <!--                             :size="105"></vue-qr-code>-->
      <!--              </v-col>-->
      <!--              <v-col cols=12 md=12 sm=8 class="mb-0">-->
      <!--                <v-row no-gutters justify="center">-->
      <!--                  <v-col cols=8 lg=6>-->
      <!--                    &lt;!&ndash; <v-img class="mr-2" src="/static/svg/fb_btn.svg" @click="share()"> </v-img> &ndash;&gt;-->
      <!--                    <v-btn class="dialog-button subtitle-1 full-width text-capitalize pa-2" @click="share()">-->
      <!--                      <v-icon class="mr-2">mdi-share-variant</v-icon>-->
      <!--                      {{ webShareApiSupported ? $t(`button.shareNow`)  : $t(`button.copy_ref`) }}-->
      <!--                    </v-btn>-->
      <!--                  </v-col>-->
      <!--                </v-row>-->
      <!--              </v-col>-->
      <!--            </v-row>-->
      <!--          </v-card-text>-->
      <!--        </v-card>-->
      <!--      </v-col>-->
    </v-row>
    <v-snackbar class="pa-0" style="z-index:1000 !important;top:7%" light timeout="1000" v-model="alertShow" width="fit-content" top centered>
      <v-alert
        v-model="alertShow"
        dismissible
        :icon="alertSuccess ? 'mdi-check-circle-outline' : 'mdi-cancel'"
        class="mb-0 pa-1"
        :class="alertSuccess ? 'profile-alert-success' : 'profile-alert-failed'"
        light
        close-icon="mdi-close"
      >
        {{ alertMessage }}
      </v-alert>
    </v-snackbar>
  </div>
</template>

<script>
import VueQrCode from 'vue-qrcode-component'
import AppLoginEmbedded from '@/components/member/loginEmbedded.vue'
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper, locale, errorCodeHelper, formValidator } from '@/util'
import { SHARED } from '@/constants/constants'
import { REFERRAL_GET_OVERVIEW, REFERRAL_POST_COMMISSION, REFERRAL_RESET_COMMISSION, REFERRAL_GET_CONFIG } from '@/store/referral.module'
import stringFormat from 'string-format'
import currencySymbolMapp from 'currency-symbol-map'
import { AFFILIATE_CLAIM_BONUS, AFFILIATE_DOWNLINE_DETAIL, AFFILIATE_DOWNLINE_LIST, AFFILIATE_UPLINE_REFERRAL } from '@/store/affiliate.bonus.module'

export default {
  name: 'status',
  components: {
    VueQrCode,
    AppLoginEmbedded
  },
  data: () => ({
    dateOfBirthMenu: false,
    stringFormat: stringFormat,
    currencySymbolMapp: currencySymbolMapp,
    language: uiHelper.getLanguage(),
    currentCurrency: uiHelper.getCurrency(),
    validator: formValidator,
    shared: SHARED,
    selectedTab: 'tier',
    dates: ['', ''],
    overview: {},
    redeemCommissionAmt: 0.0,
    share_url: '',
    alertShow: false,
    alertSuccess: false,
    alertMessage: ''
  }),
  created() {
    this.metaTag()
    this.getReferralOverview()
    this.getReferralConfig()
    this.getDownlineDetail()
    this.getUplineReferral()
  },
  mounted() {
    this.$refs.redeemForm.reset()
  },
  computed: {
    downlineListPagination() {
      return this.$store.state.affiliateBonus.affiliateDownlineList.pagination
    },
    uplineReferral() {
      return this.$store.state.affiliateBonus.affiliateUplineReferral
    },
    downlineDetail() {
      return this.$store.state.affiliateBonus.affiliateDownlineDetail
    },
    currentDate() {
      return new Date()
    },
    referralOverview() {
      return this.$store.state.referral.overview
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    totalPoint() {
      return this.overview.downline_lv1 + this.overview.downline_lv2 + this.overview.downline_lv3
    },
    redeemCommissionDetailResponseComplete() {
      return this.$store.state.referral.redeemCommissionResponse.complete
    },
    referralConfig() {
      return this.$store.state.referral.config
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    webShareApiSupported() {
      return navigator.share
    }
  },
  watch: {
    referralOverview() {
      this.overview = this.$store.state.referral.overview
      if (window.location.host.toLowerCase() === '16896kk332.com') {
        this.share_url = 'https://krikya.best' + '?refer_code=' + this.overview.my_referral_code
      } else {
        // If not, construct the share URL normally
        this.share_url = uiHelper.getHostname() + '?refer_code=' + this.overview.my_referral_code
      }
      this.redeemCommissionAmt = this.$store.state.referral.overview.commission_balance.toFixed(2)
    },
    redeemCommissionDetailResponseComplete() {
      let response = this.$store.state.referral.redeemCommissionResponse
      if (response.complete) {
        this.redeemCommissionResponseCompleteDialog(response)
        this.$store.dispatch(`${REFERRAL_RESET_COMMISSION}`)
      }
    }
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href
      uiHelper.setCanonical(currentUrl)
      const domainWithProtocol = currentUrl.split('/')[2]
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '')
      const path = window.location.pathname
      const cleanPath = path.replace(/\/(en-BD|bn-BD)/i, '')
      const Links = `https://${domain}${cleanPath}`
      const Linksen = `https://${domain}/en-BD${cleanPath}`
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`
      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
    },
    redirectDownLineReport() {
      this.$router.push({
        name: ROUTE_NAME.REFERRAL_DOWNLINE_REPORT
      })
    },
    getDownlineDetail() {
      this.$store.dispatch(`${AFFILIATE_DOWNLINE_DETAIL}`)
    },
    affiliateClaimBonus() {
      this.$store.dispatch(`${AFFILIATE_CLAIM_BONUS}`)
    },
    getDownlineList() {
      let filter = {
        start_time: this.dates[0],
        end_time: this.dates[1],
        page: this.selectedPage,
        size: 10
      }
      this.$store.dispatch(`${AFFILIATE_DOWNLINE_LIST}`, { filter })
    },
    getUplineReferral() {
      this.$store.dispatch(`${AFFILIATE_UPLINE_REFERRAL}`)
    },
    decimalTruncate(num, fixed) {
      if (num == null) {
        num = 0
      }
      let re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?')
      return parseFloat(num.toString().match(re)[0])
        .toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getReferralConfig() {
      this.$store.dispatch(`${REFERRAL_GET_CONFIG}`)
    },
    getConfig(index) {
      let config = this.referralConfig.find(c => c.level == index)
      if (config) {
        return config.percentage
      } else {
        return 0
      }
    },
    openMsgDialog(selectedMsg) {
      this.msgDialog = {
        show: true,
        msg: selectedMsg.message,
        title: selectedMsg.subject
      }
    },
    closeMsgDialog() {
      this.msgDialog = {
        show: false
      }
    },
    getReferralOverview() {
      if (this.isLoggedIn) this.$store.dispatch(`${REFERRAL_GET_OVERVIEW}`)
    },
    redeemCommissionConfirmationDialog() {
      let self = this
      if (this.$refs.redeemForm.validate()) {
        let dialog = this.initializePageDialogProperty()
        dialog.title = locale.getMessage(`label.redeemConfirmation`)
        dialog.message.push(this.stringFormat(locale.getMessage(`message.ConfirmCommission`), self.$options.filters.currency(this.redeemCommissionAmt, this.currentCurrency)))
        dialog.button.push({
          title: locale.getMessage(`label.confirm`),
          action: () => this.redeemCommission()
        })
        this.openPageDialog(dialog)
      }
    },
    redeemCommission() {
      let obj = { amount: this.redeemCommissionAmt }
      this.$store.dispatch(`${REFERRAL_POST_COMMISSION}`, {
        obj
      })
    },
    redeemCommissionResponseCompleteDialog(response) {
      this.closePageDialog()
      if (response.code != 0 && !response.success) {
        this.$parent.$parent.openAlert(false, errorCodeHelper.getErrorCodeDesc(response.code))
      } else {
        this.$parent.$parent.openAlert(true, locale.getMessage(`message.commissionRedeemSuccess`))
      }
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
    },
    share() {
      const sharing = {
        title: 'Refer a friend now and earn commission',
        text: 'Your referral code is ' + this.overview.my_referral_code + '.Refer a friend now and earn commission now! ',
        url: this.share_url
      }
      if (this.webShareApiSupported) {
        navigator.share(sharing)
      } else {
        let $f = this
        this.$copyText(this.share_url).then(function(e) {
          $f.alertMessage = 'Referral Link Copied!'
          $f.alertSuccess = true
          $f.alertShow = true
        })
      }
    }
  }
}
</script>

<style lang="scss">
.commission-content-card {
  height: 250px;
}
.border-referralbonus {
  border: 2px solid #3b9570;
  border-radius: 10px;
}
.tier-container-box {
  padding: 20px !important;
  background: #e3e3e3;
  border-radius: 0px 10px 10px 0px;
}
.border-left-radius {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.border-right-radius {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.commission-redeem-field {
  input {
    text-align: right;
  }

  .v-input__slot {
    background-color: #fff !important;
  }
}

.media-btn {
  height: 50px;
  width: 100px;
}

.commission-content-holder {
  border-radius: 0px !important;

  .qrCode > img {
    border: 0px;
    padding: 0px;
    margin: auto;
  }

  .dialog-button {
    color: var(--v-text_reverse-base);
    background-color: var(--v-secondary-base) !important;
    width: 100%;
  }
}

.v-btn.theme-button {
  // background: #FDDD04;
  text-transform: capitalize;
  border-radius: 10px;
  height: auto;
  font-size: 16px;
}

.referral-tier {
  justify-content: center;
  .tier-head {
    position: relative;
    display: flex;
    justify-content: center;

    .tier-info {
      position: absolute;
      bottom: 8px;
      right: 15%;
      cursor: pointer;
    }
  }

  .tier-container {
    margin: auto;
    max-width: 180px;
  }

  .tier-tool-tip {
    color: #ffffff;

    .tier-tool-tip-icon {
      background-color: #ffffff;
      mask: url(/static/image/referral/referral-info.svg);
      mask-repeat: no-repeat;
      mask-position: center;
      -webkit-mask: url(/static/image/referral/referral-info.svg);
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;
      cursor: pointer;
    }
  }

  .detail {
    color: white;
  }

  .mobile-carousel {
    height: 350px !important;
  }
}

@media screen and (max-width: 500px) {
  .button-texts {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 400px) {
  .button-texts {
    font-size: 10px !important;
  }
}
</style>
